import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import teamData from "../../../utils/TeamData";

function Team() {
  // Utility function to chunk the array into smaller arrays of a specified size
  function chunkArray(array, size) {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  }
  // Initialize state variables for width and height
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    // Handler to call when window size changes
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // const sortedTeamData = teamData.sort((a, b) => a.name.localeCompare(b.name));
  const chunkedTeamData = chunkArray(teamData, 5);
  const chunkedTeamData1 = chunkArray(teamData, 6);

  return (
    <div style={{ background: "#F9F2EF" }}>
      <Row style={{ display: "flex", justifyContent: "center" }}>
        <p className="team-tagline">We are a bunch of change makers!</p>
      </Row>

      {windowSize?.width < 787 ? (
        <Row style={{ justifyContent: "center" }}>
          <Col md="10">
            {chunkedTeamData1.map((teamChunk, chunkIndex) => (
              <Row
                key={chunkIndex}
                style={{ justifyContent: "center", marginBottom: "20px" }}
              >
                {teamChunk.map((team, index) => (
                  <Col
                    xs="6"
                    key={index}
                    md="2"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: 150,
                      }}
                    >
                      <img className="about-team-image-1" src={team?.url} />
                      <div>
                        <center>
                          <p className="about-team-image-1-title">
                            {team?.name}
                          </p>
                          <p className="about-team-image-1-desc">
                            {team?.profile}
                          </p>
                        </center>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            ))}
          </Col>
        </Row>
      ) : (
        <Row style={{ justifyContent: "center" }}>
          <Col md="10">
            {chunkedTeamData.map((teamChunk, chunkIndex) => (
              <Row
                key={chunkIndex}
                style={{ justifyContent: "center", marginBottom: "20px" }}
              >
                {teamChunk.map((team, index) => (
                  <Col
                    key={index}
                    md="2"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: 150,
                      }}
                    >
                      <img className="about-team-image-1" src={team?.url} />
                      <div>
                        <center>
                          <p className="about-team-image-1-title">
                            {team?.name}
                          </p>
                          <p className="about-team-image-1-desc">
                            {team?.profile}
                          </p>
                        </center>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            ))}
          </Col>
        </Row>
      )}
    </div>
  );
}

export default Team;
