import React, { useState } from "react";
import Home from "./Home";
import Vision from "./Vision";

function Main() {
  const [isTeamVisible, setTeamVisible] = useState(false);
  return (
    <>
      <Home />
      <Vision setTeamVisible={setTeamVisible} />
    </>
  );
}

export default Main;
