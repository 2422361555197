import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import libraryBg from "../../assests/images/libraryBG.png";
import newsDemo from "../../assests/images/demo-news.png";
import { Link } from "react-router-dom";

function Vision({ setTeamVisible }) {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    // Handler to call when window size changes
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <div style={{ overflow: "hidden", padding: 10, background: "#F9F2EF" }}>
        <Row>
          <Col>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="vision-page-title-text">
                We are a bunch of change makers!
              </p>
              <p className="vision-page-title-desc">
                ITTF leverages sustainability, scalability, and technology to
                create a paradigm shift by empowering communities with the tools
                to manage their forest resources sustainably.  
              </p>
              <Link to="/about-us/team">
                <button
                  // onClick={() => setTeamVisible(true)}
                  className="meet-our-team"
                >
                  Meet Our Team
                </button>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
      <div className="about-us-vision-page">
        <Row className="vision-row">
          {windowSize?.width < 787 ? (
            <div className="vision-container-1">
              <p className="our-vision-text">Our Vision</p>
              <p className="our-vision-desc-text">
                The Initiative on Transformative Forest Futures is a
                longitudinal program that aims to produce evidence for
                replicable models of large-scale development and economic
                well-being driven by forest-centric value chains. The bedrock of
                our model is built on sustainability, scalability, and
                technology to synergize public policy goals, statutory
                provisions, multi-stakeholder engagement, and market
                opportunities in India’s forested landscapes. 
                <br></br>
                <br></br>
                Sustainability is crucial for establishing an ethical,
                transparent, and accountable framework that ensures the
                long-term viability of forests and livelihoods while protecting
                against resource depletion and market volatility. Responsible
                scaling is key to unlocking the potential and returns on
                investment in forest-based Seasonal Forest Products, ensuring
                benefits, greater bargaining power, and fair prices for primary
                producers, while upholding social and environmental justice.
                Technologies like AI, machine learning, IoT, blockchain, and
                smart contracts are essential for creating a transparent digital
                ecosystem among communities, governments, and businesses. They
                build trust, maximize resource use, and mitigate environmental
                risks. 
              </p>
            </div>
          ) : (
            <div className="vision-container">
              <p className="our-vision-text">Our Vision</p>
              <p className="our-vision-desc-text">
                The Initiative on Transformative Forest Futures is a
                longitudinal program that aims to produce evidence for
                replicable models of large-scale development and economic
                well-being driven by forest-centric value chains. The bedrock of
                our model is built on sustainability, scalability, and
                technology to synergize public policy goals, statutory
                provisions, multi-stakeholder engagement, and market
                opportunities in India’s forested landscapes. 
                <br></br>
                <br></br>
                Sustainability is crucial for establishing an ethical,
                transparent, and accountable framework that ensures the
                long-term viability of forests and livelihoods while protecting
                against resource depletion and market volatility. Responsible
                scaling is key to unlocking the potential and returns on
                investment in forest-based Seasonal Forest Products, ensuring
                benefits, greater bargaining power, and fair prices for primary
                producers, while upholding social and environmental justice.
                Technologies like AI, machine learning, IoT, blockchain, and
                smart contracts are essential for creating a transparent digital
                ecosystem among communities, governments, and businesses. They
                build trust, maximize resource use, and mitigate environmental
                risks. 
              </p>
            </div>
          )}
        </Row>
        <video className="background-video" autoPlay={true} loop muted>
          <source
            src={
              "https://iofe-greenbase-bucket.s3.amazonaws.com/iofe-attachments/Comp+2.mp4"
            }
            type="video/mp4"
          />
        </video>
        <div></div>
      </div>
      <Row
        style={{
          justifyItems: "center",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          padding: 20,
        }}
      >
        <p className="join-us-title">Are you ready to make an impact?</p>
        <button className="join-us-button">Join Us</button>
      </Row>

      <div className="vision-video-section">
        <img src={libraryBg} className="background-video" />
        <div style={{ width: "100%" }}>
          <Row className="join-us-card-row">
            <div className="join-us-card">
              <p className="join-us-card-title"> Internship</p>

              <p className="join-us-card-desc">
                ed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis
              </p>
              <button className="join-internships">Internships</button>
            </div>
            <div className="join-us-card">
              <p className="join-us-card-title"> Jobs</p>

              <p className="join-us-card-desc">
                ed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis
              </p>
              <button className="join-internships">Jobs</button>
            </div>
            <div className="join-us-card">
              <p className="join-us-card-title"> Part-Time Jobs</p>

              <p className="join-us-card-desc">
                ed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis
              </p>
              <button className="join-internships">Part-Time Jobs</button>
            </div>
          </Row>
        </div>
      </div>
      {/* <div style={{background: 'rgb(215 174 161)'}}>
  <Row>
<Col md="4" style={{display:'flex',flexDirection:'column',justifyContent:'flex-end',padding:25}}>
<p className='media-mention-title'>Our Media Mentions</p>
<p className='media-mention-desc'>Ed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae </p>
<button className='media-mention-button'>All Our Media mentions</button>
</Col>
<Col md="8">
<img src={newsDemo} style={{width:'100%'}}/>
</Col>
  </Row>
</div> */}
      <div>
        <Row className="about-us-vision-endline-row">
          <p className="about-us-vision-endline-text">
            Want to engage with us?
          </p>

          <button
            onClick={() => setTeamVisible(true)}
            className="about-us-vision-endline-button"
          >
            Engage with us
          </button>
        </Row>
      </div>
    </div>
  );
}

export default Vision;
