import React from "react";
import Home from "./Home";
import Team from "./Team";
import ContactForm from "../ContactForm";
// import ContactForm from "./ContactForm";

function Main() {
  return (
    <>
      <Home />
      <Team />
      <ContactForm />
    </>
  );
}

export default Main;
