import React from "react";
import workData from "../../utils/workData";

function OurWorkCardsSection() {
  return (
    <div className="work-card-section">
      <div className="work-card-container">
        <div className="work-grid-container">
          {workData.map((info, index) => {
            return (
              <div className="work-card">
                <div className="work-card-img">
                  <img src={info.img} className="" alt="card-img" />
                </div>
                <div className="work-card-title">
                  <h6 className="">{info.title}</h6>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default OurWorkCardsSection;
